import { css, styled, thd, up } from '@smooth-ui/core-sc';
import { NavLink } from 'react-router-dom';

export default styled(NavLink)`
  border-left: 3px solid transparent;
  color: ${thd('customMenuColor', '#54707c')};
  display: block;
  font-size: 20px;
  font-weight: normal;
  padding: 0.5em;
  ${props =>
    props.depth && props.depth > 1 && `padding-left: ${props.depth - 1}em;`}
  text-decoration: none;

  &:hover {
    color: ${thd('customMenuHighlightColor', '#000')};
  }

  &:focus,
  &.menu-active:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem ${thd('customMenuFocus', 'rgba(84,112,124,0.25)')}
      inset;
  }

  &.menu-active {
    border-left-color: ${thd('customMenuHighlightColor', '#54707c')};
    color: ${thd('customMenuHighlightColor', '#000')};
    font-weight: bold;
  }

  ${up(
    'lg',
    css`
      font-size: 14px;
      ${props =>
        // If it's the top level
        (!props.depth || props.depth < 2) &&
        css`
          border-left: 0 none;
          border-bottom: 3px solid transparent;
          padding: 0.25rem 0.25rem;
          margin: 0 0.5rem 0.5rem 0.5rem;
        `}

      &.menu-active {
        position: relative;
        border-color: ${thd('secondary', 'gold')};
        &:hover {
          color: ${thd('customMenuHighlightColor', '#000')};
        }
      }
    `
  )}
`;
